@media screen and (min-width: 600px) {

    .App {
        height: 100vh;
    }

    .Container--main {
        position: fixed;
        height: auto !important;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}


.App {
    font-family: 'Noto Sans', sans-serif;;
    text-align: center;
    width: 100%;
    background-image: url("./img/background-picture.jpg");
    background-size: cover;
    background-position: center;
    align-content: center;
    align-items: center;
}

.Container--main {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    background-color: rgba(206, 213, 223, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.55);
}

.Container--profile-flex {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Image--profile-picture {
    max-width: 200px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.55);
    box-shadow: 0 0 25px -5px rgba(255, 255, 255, 0.4);
}

.Logo--of {
    width: 150px;
    text-decoration: none;
}

.Logo--twitter {
    width: 50px;
    height: 50px;
    text-decoration: none;
}

a {
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.Link--of {
    display: block;
    padding-left: 10px;
}

.Logo--fansly {
    height: 50px;
    text-decoration: none;
}

.Logo--insta {
    width: 60px;
    height: 60px;
    text-decoration: none;
}

.Logo--mv {
    height: 45px;
    text-decoration: none;
}

.Logo--twitch {
    width: 45px;
    height: 45px;
    text-decoration: none;
}

.Logo--tiktok {
    width: 45px;
    height: 45px;
    text-decoration: none;
}

.Bubble--social {
    background-color: rgb(255, 255, 255);
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid rgba(2, 13, 9, 0.4);
    box-shadow: 0 0 5px -5px rgba(0, 0, 0, 0.25);
    margin: 5px;
    height: 55px;
}

.Container--profile-of-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}